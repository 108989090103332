import React from "react";
import { graphql } from "gatsby";
import { langMap } from "@v4/utils/i18nHelper";
import Layout from "../../components/layout/Layout";
import Seo from "@v4/gatsby-theme-talend/src/components/Seo/Seo";
import Link from "@v4/gatsby-theme-talend/src/components/Link";
import { I18nProvider } from "@v4/utils/i18nContext";
import { pageNotFoundTranslations } from "../../translations/404";
import { getLangPrefixedPath } from "@v4/utils/pathPrefix";

export const query = graphql`
    query($locale: String!) {
        mainNav: contentfulMenu(name: { eq: "Main Nav" }, node_locale: { eq: $locale }) {
            ...MainMenu
        }
        mainNavCta: contentfulMenu(name: { eq: "Main Nav CTA" }, node_locale: { eq: $locale }) {
            ...MainMenu
        }
        footerNav: contentfulMenu(name: { eq: "Footer Nav" }, node_locale: { eq: $locale }) {
            ...FooterNav
        }
        footerSocials: contentfulMenu(name: { eq: "Footer Socials" }, node_locale: { eq: $locale }) {
            ...FooterSocials
        }
        footerSubMenu: contentfulMenu(name: { eq: "Footer Sub Menu" }, node_locale: { eq: $locale }) {
            ...FooterSubMenu
        }
    }
`;

const NotFoundPage = ({ data, location, pageContext }) => {
    const { locale } = pageContext;
    const footerData = {
        footerNav: data.footerNav,
        footerSocials: data.footerSocials,
        footerSubMenu: data.footerSubMenu,
    };

    const headerData = {
        mainNav: data.mainNav,
        mainNavCta: data.mainNavCta,
    };

    const translatedPaths = Object.keys(langMap).reduce(
        (acc, lang) => ({ ...acc, [lang]: getLangPrefixedPath(location.pathname, locale) }),
        {}
    );

    return (
        <I18nProvider translatedPaths={translatedPaths} locale={locale}>
            <Layout headerMenu={headerData} footerMenu={footerData} hasMinimalNav={null}>
                <Seo title={pageNotFoundTranslations[locale].seoTitle} />
                {/*<Helmet bodyAttributes={{ class: pageNotFoundStyles.bodyWrapper }} />*/}
                <div>
                    <h1>{pageNotFoundTranslations[locale].heading}</h1>
                    <h2>{pageNotFoundTranslations[locale].subHeading}</h2>
                    <Link to={`/`}>{pageNotFoundTranslations[locale].linkText}</Link>
                </div>
            </Layout>
        </I18nProvider>
    );
};

export default NotFoundPage;
