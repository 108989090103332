export const pageNotFoundTranslations = {
    en: {
        seoTitle: "404: Not found",
        heading: "Oops!",
        subHeading: "We can't find the page you're looking for.",
        linkText: "Return to home page.",
    },
    fr: {
        seoTitle: "404: Not found",
        heading: "Oops!",
        subHeading: "We can't find the page you're looking for.",
        linkText: "Return to home page.",
    },
};
